import * as React from 'react';
import Helmet from 'react-helmet';

import { Store } from 'components/store/Store';

export default () => (
  <>
    <Helmet title="Store" />

    <Store
      title="Not there yet!"
      text={
        <React.Fragment>
          <p>Due to Icelandic law it is prohibited to sell alcohol directly
          to individuals. Our beers are available at <a href="https://www.vinbudin.is/heim/vorur/vorur.aspx/?text=malbygg" target="_blank">Vínbúðin</a> and at bars/restaurants.</p>
        </React.Fragment>
      }
    />
  </>
);
