import * as React from 'react';
import { TimelineLite } from 'gsap';
import { Container } from 'components/container/Container';
import { Row } from 'components/row/Row';
import { Image } from 'components/image/Image';
import { useInView } from 'react-intersection-observer';

import s from './Store.scss';

interface IStoreProps {
  title: string;
  text: React.ReactNode;
  children: React.ReactNode;
}

export const Store = ({ title, text, children }: IStoreProps) => {
  const refTitle = React.useRef<HTMLHeadingElement>(null);
  const refText = React.useRef<HTMLDivElement>(null);

  const [ref, inView] = useInView({ triggerOnce: true, rootMargin: '-10% 0%'  });

  React.useEffect(() => {
    if (inView) {
      const t = new TimelineLite();

      t.addLabel('start', 0.5);

      if (refTitle.current) {
        t.fromTo(
          refTitle.current,
          1,
          { autoAlpha: 0, y: '40px' },
          { autoAlpha: 1, y: '0px', ease: 'Power4.easeInOut' },
          'start',
        );
      }

      if (refText.current) {
        t.fromTo(
          refText.current,
          1.2,
          { autoAlpha: 0, y: '40px' },
          { autoAlpha: 1, y: '0px', ease: 'Power4.easeInOut' },
          'start+=0.3',
        );
      }
    }
  }, [inView]);

  return (
    <Container>
      <div className={s.store} ref={ref}>
        <Row>
          <div className={s.store__col}>
            <h1 className={s.store__heading} ref={refTitle}>{title}</h1>
          </div>

          <div className={s.store__text} ref={refText}>
            {text}
          </div>
        </Row>

      </div>
    </Container>
  );
};
